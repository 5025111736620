// import React, { useState } from 'react';
// import AWS from 'aws-sdk';
// import { useNavigate } from 'react-router-dom';
// import './Organization.css';

// AWS.config.region = 'us-east-1'; // Update to your region

// const Organization = () => {
//     const [organizationName, setOrganizationName] = useState('');
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const cognitoISP = new AWS.CognitoIdentityServiceProvider();

//         const params = {
//             UserPoolId: 'us-east-1_xnKphkpGL', // Your User Pool ID
//             Filter: `cognito:identity_provider_name = "${organizationName}"`
//         };

//         try {
//             const result = await cognitoISP.listUsers(params).promise();
//             if (result.Users && result.Users.length > 0) {
//                 // Organization name matches the SAML provider; redirect to home.js
//                 navigate('/home');
//             } else {
//                 alert('Invalid organization name or not associated with an identity provider.');
//             }
//         } catch (error) {
//             console.error('Authentication failed:', error);
//             alert('Error during organization validation.');
//         }
//     };

//     return (
//         <div className="main">
//             <p className="sign" align="center">Choose Your Organization</p>
//             <form className="form1" onSubmit={handleSubmit}>
//                 <input
//                     className="un"
//                     type="text"
//                     align="center"
//                     placeholder="Organization Name"
//                     value={organizationName}
//                     onChange={(e) => setOrganizationName(e.target.value)}
//                 />
//                 <button className="submit" align="center">Submit</button>
//             </form>
//         </div>
//     );
// };

// export default Organization;








import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { useNavigate } from 'react-router-dom';
import './Organization.css';

AWS.config.region = process.env.REACT_APP_AWS_REGION; // Set region from environment variable

const Organization = () => {
    const [organizationName, setOrganizationName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const cognitoISP = new AWS.CognitoIdentityServiceProvider();
        const params = {
            AuthFlow: 'ADMIN_NO_SRP_AUTH',
            ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
            UserPoolId: process.env.REACT_APP_USER_POOL_ID,
            AuthParameters: {
                'USERNAME': organizationName,
                'PASSWORD': 'dummyPassword' // Replace this if required for SAML flow
            }
        };

        try {
            const result = await cognitoISP.adminInitiateAuth(params).promise();
            if (result.AuthenticationResult) {
                navigate('/home');
            } else {
                alert('Invalid organization name or not associated with an identity provider.');
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            alert('Error during organization validation.');
        }
    };

    return (
        <div className="main">
            <p className="sign" align="center">Choose Your Organization</p>
            <form className="form1" onSubmit={handleSubmit}>
                <input
                    className="un"
                    type="text"
                    align="center"
                    placeholder="Organization Name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                />
                <button className="submit" align="center">Submit</button>
            </form>
        </div>
    );
};

export default Organization;
