import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dbClient from './aws-config'; // Import the DynamoDB client
import img1 from './pict.png'

const Login = () => {
    const [username, usernameUpdate] = useState('');
    const [password, passwordUpdate] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
            toast.warning('Please Enter Username');
        }
        if (password === '' || password === null) {
            result = false;
            toast.warning('Please Enter Password');
        }
        return result;
    }

    const proceedLogin = async (e) => {
        e.preventDefault();

        if (validate()) {
            const params = {
                TableName: 'CostOptimizationAuthentication',
                Key: {
                    id: username,          // Partition Key
                    password: password     // Sort Key
                }
            };

            try {
                const result = await dbClient.get(params).promise();
                const user = result.Item;

                if (!user) {
                    toast.error('Invalid username or password');
                } else if (user.password === password) {
                    toast.success('Login successful');
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('userrole', user.role || 'user'); // Assuming you have a role field; default to 'user'
                    navigate('/');
                } else {
                    toast.error('Invalid password');
                }
            } catch (err) {
                toast.error('Login Failed due to: ' + err.message);
            }
        }
    };

    return (
        <div className="row" style={{ height: '100vh', justifyContent: 'center', alignItems: 'center', marginRight:'300px'}}>
            <div className="offset-lg-3 col-lg-6">
                <form onSubmit={proceedLogin} className="container">
                    <div className="card" >
                        <div className="card-header" >
                            <img 
                                src={img1} 
                                alt="Company Logo" 
                                style={{ width: '320px', height: '105px', marginBottom: '30px' }} 
                            />
                            <h2> Login</h2>
                        </div>
                        <div className="card-body">
                            <div className="form-group" style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                                <label>User Name <span className="errmsg">&nbsp;</span></label>
                                <input 
                                    value={username} 
                                    onChange={e => usernameUpdate(e.target.value)} 
                                    className="form-control"
                                    style={{ width: '300px' }}
                                />
                            </div>
                            <div className="form-group" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label>Password <span className="errmsg">&nbsp; &nbsp;</span></label>
                                <input 
                                    type="password" 
                                    value={password} 
                                    onChange={e => passwordUpdate(e.target.value)} 
                                    className="form-control"
                                    style={{ width: '300px' }}
                                />
                            </div>
                        </div>
                        <div className="card-footer" style={{textAlign:'center'}}>
                            <button type="submit" className="btn" style={{ marginTop: '6.5px', backgroundColor:'#7722F1', color:'whitesmoke'}}>Submit</button> 
                            <span style={{ margin: '0 5px' }}></span>

                            <Link className="btn" to={'/register'}style={{ marginLeft: '2px', backgroundColor: '#08E188', color:'whitesmoke'}}>Sign Up</Link>

                                           {/* Add the "Login with SSO" link here */}
                                           <div style={{ marginTop: '10px' }}>
                                           <Link className="btn" to={'/organization'} style={{ fontSize: '14px' }}>Login with SSO</Link>

</div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
