const API_URLS = {
  LAMBDA_FUNCTIONS: 'https://oii5aijp15.execute-api.us-east-1.amazonaws.com/dev/lamda',
  EC2_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ec2recommendation',
  EC2_MIGRATE_TO_GRAVITON: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ec2-migrate-to-graviton',
  EC2_STOP_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ec2-stop',
  EBS_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ebs-recommendation',
  EBS_RIGHT_SIZING: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ebs-right-size',
  EBS_UPGRADE_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ebs-upgrade',
  NIC_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/nic-recommendation',
  AMI_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ami-recommendation',
  EIP_RECOMMENDATION: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/eip-recommendation',
  SNAPSHOT : 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/snapshot-optimization',
  HANDLE_INSTANCE_MIGRATE: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/migrate-ec2',
  HANDLE_BACKUP: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/create-snapshot',
  DELETE_EBS_SNAPSHOT: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/delete-ebs',
  DELETE_NIC: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/delete-nic',
  CREATE_AMI: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/create-ami',
  DELETE_EIP: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/delete-eip',
  COST: 'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/cost-savings',
  // Add more APIs here as needed
};

console.log("APIConfig loaded:", API_URLS);



export default API_URLS;