// import { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import pict from './unnamed (1).png'
// import './LauncherIcon.css';  // Launcher Icon styles
// import './LauncherMenu.css';  // Launcher Menu styles
// import imga from './Coinss.png';
// import API_URLS from './APIConfig';
// const Appheader = () => {
//     const [displayusername, displayusernameupdate] = useState('');
//     const [showmenu, showmenuupdateupdate] = useState(false);
//     const [menuOpen, setMenuOpen] = useState(false); 
//     const usenavigate = useNavigate();
//     const [costSavings, setCostSavings] = useState(null);
//     const location = useLocation();

//     const services = [
//         { name: 'EC2', link: '#', imgSrc: '/assets/EC2.png' },  
//         { name: 'S3', link: '#', imgSrc: 'assets/s3.png' },
//         { name: 'RDS', link: '#', imgSrc: '/assets/RDS.png' },
//         { name: 'Lambda', link: '/Lambda', imgSrc: '/assets/Lambda.png' },
//         { name: 'CloudFront', link: '#', imgSrc: '/assets/Cloudfront.png' },
//         { name: 'SNS', link: '#', imgSrc: '/assets/SNS.png' },
//         { name: 'SQS', link: '#', imgSrc: '/assets/SQS.png' },
//         { name: 'ECS', link: '#', imgSrc: '/assets/ECS.png' },
//         { name: 'EKS', link: '#', imgSrc: '/assets/EKS.png' },
//     ];


//     useEffect(() => {
//         // Fetch the cost savings data from API
//         const fetchCostSavings = async () => {
//             try {
//                 const response = await fetch(API_URLS.COST); // Using the updated URL from APIConfig
//                 const data = await response.json();
//                 setCostSavings(data.body); // Store the cost savings value
//             } catch (error) {
//                 console.error('Error fetching cost savings:', error);
//             }
//         };


//         fetchCostSavings();

//         if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/organization') {
//             showmenuupdateupdate(false);
//         } else {
//             showmenuupdateupdate(true);
//             let username = sessionStorage.getItem('username');
//             if (username === '' || username === null) {
//                 usenavigate('/login');
//             } else {
//                 displayusernameupdate(username);
//             }
//         }

//     }, [location])

//     const handleServiceClick = (link) => {
//         usenavigate(link);
//     };
    
//     return (
//         <div>
//             {showmenu &&
//                 <div className="header">
                    
//                     <Link to="/">
//                     <img src={pict} alt="Logo" className="Applogo"/>
//                     </Link>

//                     <img src={imga} alt="Logo" className="imaged"/>
//                     {costSavings && (
//                         <div className="cost-savings" style={{ marginRight: '10px', fontWeight: 'bold' }}>
//                             Cost Savings: ${parseFloat(costSavings).toFixed(2)}
//                         </div>
//                     )}
//                     {/* <FallingDollars costSavings={costSavings} /> */}


                    
//                     <span style={{ marginLeft: '48%' , marginTop:'16px'}}>Welcome <b>{displayusername}</b></span>       
//                     <Link className="btn" to={'/login'} style={{ float: 'right', backgroundColor: '#f95959', color:'whitesmoke' }} >  Logout</Link>
//                                  {/* 9-Dot Launcher Icon */}
//                         <div className="launcher-icon" onClick={() => setMenuOpen(!menuOpen)} style={{ float: 'right', marginRight: '15px' }}>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                         <div className="dot"></div>
//                     </div>
//                      {/* Dropdown Menu */}
//                      {menuOpen && (
//                         <div className="launcher-menu">
//                             <div className="services-grid">
//                                 {services.map((service, index) => (
//                                     <a key={index} href={service.link} className="service-icon">
//                                         <img src={service.imgSrc} alt={service.name} />
//                                         <span>{service.name}</span>
//                                     </a>
//                                 ))}
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             }
//         </div>
//     );
// }

// export default Appheader;



import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import pict from './unnamed (1).png';
import './LauncherIcon.css';  // Launcher Icon styles
import './LauncherMenu.css';  // Launcher Menu styles
import imga from './Coinss.png';
import API_URLS from './APIConfig';

const Appheader = () => {
    const [displayusername, displayusernameupdate] = useState('');
    const [showmenu, showmenuupdateupdate] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); 
    const usenavigate = useNavigate();
    const [costSavings, setCostSavings] = useState(null);
    const location = useLocation();

    const services = [
        { name: 'EC2', link: '#', imgSrc: '/assets/EC2.png' },  
        { name: 'S3', link: '#', imgSrc: 'assets/s3.png' },
        { name: 'RDS', link: '#', imgSrc: '/assets/RDS.png' },
        { name: 'Lambda', link: '/lambda', imgSrc: '/assets/Lambda.png' },  // Ensure route is lowercase
        { name: 'CloudFront', link: '#', imgSrc: '/assets/Cloudfront.png' },
        { name: 'SNS', link: '#', imgSrc: '/assets/SNS.png' },
        { name: 'SQS', link: '#', imgSrc: '/assets/SQS.png' },
        { name: 'ECS', link: '#', imgSrc: '/assets/ECS.png' },
        { name: 'EKS', link: '#', imgSrc: '/assets/EKS.png' },
    ];


    useEffect(() => {
        // Fetch the cost savings data from API
        const fetchCostSavings = async () => {
            try {
                const response = await fetch(API_URLS.COST);
                const data = await response.json();
                setCostSavings(data.body);
            } catch (error) {
                console.error('Error fetching cost savings:', error);
            }
        };
        

        fetchCostSavings();

        if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/organization') {
            showmenuupdateupdate(false);
        } else {
            showmenuupdateupdate(true);
            let username = sessionStorage.getItem('username');
            if (!username) {
                usenavigate('/login');
            } else {
                displayusernameupdate(username);
            }
        }
    }, [location, usenavigate]);

    const handleServiceClick = (link) => {
        if (link !== '#') {
            usenavigate(link);
        }
    };
    
    return (
        <div>
            {showmenu &&
                <div className="header">
                    
                    <Link to="/">
                        <img src={pict} alt="Logo" className="Applogo" />
                    </Link>

                    <img src={imga} alt="Logo" className="imaged" />
                    {costSavings && (
                        <div className="cost-savings" style={{ marginRight: '10px', fontWeight: 'bold' }}>
                            Cost Savings: ${parseFloat(costSavings).toFixed(2)}
                        </div>
                    )}
                    {/* <FallingDollars costSavings={costSavings} /> */}

                    <span style={{ marginLeft: '48%' , marginTop:'16px' }}>Welcome <b>{displayusername}</b></span>       
                    <Link className="btn" to={'/login'} style={{ float: 'right', backgroundColor: '#f95959', color:'whitesmoke' }}>Logout</Link>

                    <div className="launcher-icon" onClick={() => setMenuOpen(!menuOpen)} style={{ float: 'right', marginRight: '15px' }}>
                        {[...Array(9)].map((_, i) => <div key={i} className="dot"></div>)}
                    </div>

                    {menuOpen && (
                        <div className="launcher-menu">
                            <div className="services-grid">
                                {services.map((service, index) => (
                                    <div key={index} onClick={() => handleServiceClick(service.link)} className="service-icon">
                                        <img src={service.imgSrc} alt={service.name} />
                                        <span>{service.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export default Appheader;