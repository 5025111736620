import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dbClient from './aws-config'; // Import the DynamoDB client
import image from './unnamed (1).png'
import {validateEmail} from "../src/utils"; 
import './Register.css'
const Register = () => {
    const [id, idChange] = useState("");
    const [name, nameChange] = useState("");
    const [password, passwordChange] = useState("");
    const [email, emailChange] = useState("");
    const [phone, phoneChange] = useState("");
    const [country, countryChange] = useState("india");
    const [role, roleChange] = useState("user"); // New state for role

    const navigate = useNavigate();

    const isValidate = () => {
        let isProceed = true;
        let errorMessage = 'Please enter the value in ';
        console.log("Password entered:", password);
        if (id === null || id === '') {
            isProceed = false;
            errorMessage += ' Username';
        }
        if (name === null || name === '') {
            isProceed = false;
            errorMessage += ' Fullname';
        }
        if (password === null || password === '') {
            isProceed = false;
            errorMessage += ' Password';
        } else {
            // Password validation: minimum 8 characters, at least one digit, one uppercase letter, one lowercase letter, and one special character
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-#])[A-Za-z\d@$!%*?&_\-#]{8,}$/;
            if (!passwordPattern.test(password)) {
                isProceed = false;
                errorMessage += ' Password does not meet the requirements (min 8 characters, one digit, one special character, one uppercase, and one lowercase letter).';
            }
        }
    
        if (email === null || email === '') {
            isProceed = false;
            errorMessage += ' Email';
        }
        if (role === null || role === '') {
            isProceed = false;
            errorMessage += ' Role'; // Check for role
        }

        if (!isProceed) {
            toast.warning(errorMessage);
        } else {
            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
                // Email is valid
            } else {
                isProceed = false;
                toast.warning('Please enter a valid email');
            }
        }
        return isProceed;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isValidate()) {
            const regObj = { id, name, password, email, phone, country, role }; // Include role in registration object

            const params = {
                TableName: 'CostOptimizationAuthentication',
                Item: {
                    id: regObj.id,           // Partition Key
                    password: regObj.password, // Sort Key
                    name: regObj.name,
                    email: regObj.email,
                    phone: regObj.phone,
                    country: regObj.country,
                    address: regObj.address,
                    gender: regObj.gender,
                    role: regObj.role // Save role to DynamoDB
                }
            };

            try {
                await dbClient.put(params).promise();
                toast.success('Registered successfully.');
                navigate('/login');
            } catch (err) {
                toast.error('Failed: ' + err.message);
            }
        }
    }



    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'F1F3F4' }}>
            <div className="col-lg-6">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card">
                        <div className="card-header">
                            <img src={image} alt="Logo" className="logo" />
                            <br/><br/>
                            <h1>Register</h1>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}}>
                                        <label>Full Name <span className="errmsg">*</span></label>
                                        <input value={name} onChange={e => nameChange(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}}>
                                        <label>Email <span className="errmsg">*</span></label>
                                        <input value={email} onChange={e => emailChange(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}}>
                                        <label>User Name <span className="errmsg">*</span></label>
                                        <input value={id} onChange={e => idChange(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}}>
                                        <label>Password <span className="errmsg">*</span></label>
                                        <input value={password} onChange={e => passwordChange(e.target.value)} type="password" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}}>
                                        <label>Phone <span className="errmsg"></span></label>
                                        <input value={phone} onChange={e => phoneChange(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group" style={{marginBottom:'10px'}} >
                                        <label>Country <span className="errmsg">*</span></label>
                                        <select value={country} onChange={e => countryChange(e.target.value)} className="form-control">
                                            <option value="india">India</option>
                                            <option value="usa">USA</option>
                                            <option value="U.K">U.K</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Canada">Canada</option>
                                            <option value="singapore">Singapore</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<div className="Field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '300px' , marginBottom: '10px', width: '100%' }}>
                                              <label style={{ marginRight: '20px', position: 'relative' }}>
                                             Role <sup></sup>
                                         <div className="tooltip">
                                                Who are you? Users doesn't have Migrate Access of Instances!!
                                     </div>
                                     </label>
                                     <select value={role} onChange={e => roleChange(e.target.value)} className="form-control" style={{ width: 'auto' }}>
                                      <option value="role">Select Role</option>
                                      <option value="user">User</option>
                                      <option value="admin">Admin</option>
                                         </select>
                                        </div>

                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" style={{ marginTop: '6.5px' }}>Register</button>
                            <span style={{ margin: '0 5px' }}></span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Register;   