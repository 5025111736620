import AWS from 'aws-sdk';
AWS.config.update({
  region: 'us-east-1',
  endpoint: 'https://dynamodb.us-east-1.amazonaws.com',
  accessKeyId: 'AKIAQW3OX4PYZNVRWLUL',
  secretAccessKey: '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',
  // accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  // secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
});

const dbClient = new AWS.DynamoDB.DocumentClient();

export default dbClient;