import logo from './logo.svg';
import './App.css';
import './Home.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import { ToastContainer } from 'react-toastify';
import Appheader from './Appheader';
import Lambda from './Lambda';
import Organization from './Organization';

function App() {
  return (
    <div className="App">
      <ToastContainer theme='colored' position='top-center'></ToastContainer>
      <BrowserRouter>
      <Appheader></Appheader>

          <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/organization' element={<Organization />} />
          <Route path='/Lambda' element={<Lambda />} /> 
        </Routes>
      
      </BrowserRouter>
      
    </div>
  );
}

export default App;