import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, CircularProgress, Typography, TablePagination, Alert, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import API_URLS from './APIConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontSize: '0.8rem',
}));

const HeaderTableCell = styled(StyledTableCell)({
  backgroundColor: '#0AE289', // Light green background color for header
  // backgroundColor: '#b3cee5', // Light blue background color for header
  fontWeight: 'bold',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Lambda = () => {
  const [lambdaFunctions, setLambdaFunctions] = useState([]);
  const [filteredLambdas, setFilteredLambdas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const fetchLambdaFunctions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(API_URLS.LAMBDA_FUNCTIONS, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        const parsedData = response.data.body ? JSON.parse(response.data.body) : response.data;
        if (Array.isArray(parsedData)) {
          setLambdaFunctions(parsedData);
          setFilteredLambdas(parsedData);
        } else {
          setError('Unexpected data format.');
        }
      } catch (err) {
        setError('Error fetching Lambda functions. Please check the console for more details.');
        console.error('Error fetching Lambda functions:', err);
      }
      setLoading(false);
    };
    fetchLambdaFunctions();
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = filteredLambdas.map((lambda) => lambda.arn);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelectRow = (arn) => {
    const selectedIndex = selected.indexOf(arn);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, arn);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (arn) => selected.indexOf(arn) !== -1;

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!loading && lambdaFunctions.length === 0) return <Typography variant="h6">No Lambda functions found.</Typography>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom style={{ marginTop: '100px' }}>
        Lambda Functions
      </Typography>
      <TableContainer component={Paper} style={{ 
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
          overflow: 'hidden', 
          position: 'relative', 
          backdropFilter: 'blur(6px)'
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < filteredLambdas.length}
                  checked={filteredLambdas.length > 0 && selected.length === filteredLambdas.length}
                  onChange={handleSelectAll}
                />
              </HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Name</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Region</HeaderTableCell>  
              <HeaderTableCell style={{textAlign:'center'}}>Environment</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Memory</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Final Memory</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>is_validated</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Best Cost</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Best Time</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Final Memory</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Is Optimized</HeaderTableCell>
              <HeaderTableCell style={{textAlign:'center'}}>Category</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLambdas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lambda) => {
              const isItemSelected = isSelected(lambda.name);
              return (
                <StyledTableRow key={lambda.name} selected={isItemSelected}>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={() => handleSelectRow(lambda.name)}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda.name}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda.region}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda.environment}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda.memory}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['final-mmemory']}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['is_validated'] ? 'True' : 'False'}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['best-cost']}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['best-time']}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['final-mmemory']}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda['is-optimized'] ? 'True' : 'False'}</StyledTableCell>
                  <StyledTableCell style={{textAlign:'center'}}>{lambda.category}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLambdas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Lambda;